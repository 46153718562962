import React, { useContext, useEffect, useState } from "react";
import "./Form.css";
import { FormContext } from "./FormContext";
import { FormHeader } from "./FormHeader";
import { useNavigate } from "react-router-dom";
import { differenceInDays, startOfDay } from "date-fns";
import Modal from "./../../components/Modal/Modal";

import { updateOneForm } from "../../../api/api";

import { useFormStateValidation } from "./FormStateValidation";

export const Form = () => {
  useFormStateValidation();

  const { formState } = useContext(FormContext);
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [filter, setFilter] = React.useState("Orçamento");

  useEffect(() => {
    if (!formState.form) {
      navigate("/artist");
    }
  }, [formState, navigate]);

  useEffect(() => {
    const checkOpened = async () => {
      if (Object.keys(formState).length === 1 && !formState.opened) {
        await updateOneForm({ _id: formState._id, data: { opened: true } });
      }
    };

    checkOpened();
  }, [formState]);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalVisible(true);
  };

  const handleSubmit = () => {
    navigate("../valor");
  };

  const callClient = () => {
    const whatsappNumber = formState.form.phone;
    const whatsappMessage = encodeURIComponent(
      `Olá, ${formState.form.name}! Tudo bem?`
    );
    const link = document.createElement("a");
    link.href = `whatsapp://send?phone=55${whatsappNumber}&text=${whatsappMessage}`;
    link.click();
  };
  return (
    <div>
      {!formState.answered ? (
        <FormHeader rightText={""} midText={"Orçamento Recebido"} />
      ) : (
        <FormHeader rightText={""} midText={"Orçamento Respondido"} />
      )}

      <div className="body-form-artist">
        <div className="details-wrapper">
          <div className="details">
            {!formState.answered && (
              <div className="div">
                <div className="account-circle-wrapper">
                  <img
                    className="account-circle"
                    alt="Account circle"
                    src="/img/formartist/account-circle.png"
                  />
                </div>
                <div className="frame-2">
                  <div className="text-wrapper-2">{formState.form?.name}</div>
                  <div className="text-wrapper-3">
                    {differenceInDays(
                      startOfDay(new Date()),
                      startOfDay(new Date(formState.date))
                    ) === 0
                      ? "Hoje"
                      : `${differenceInDays(
                          startOfDay(new Date()),
                          startOfDay(new Date(formState.date))
                        )} dias`}
                  </div>
                </div>
              </div>
            )}

            {formState.answered && (
              <div className="filters">
                <div
                  className={`filter-${
                    filter === "Orçamento" ? "checked" : "unchecked"
                  }`}
                  onClick={() => setFilter("Orçamento")}
                >
                  <div
                    className={`text-wrapper-${
                      filter === "Orçamento" ? "checked" : "unchecked"
                    }`}
                  >
                    Orçamento
                  </div>
                </div>
                <div
                  className={`filter-${
                    filter === "Projeto" ? "checked" : "unchecked"
                  }`}
                  onClick={() => setFilter("Projeto")}
                >
                  <div
                    className={`text-wrapper-${
                      filter === "Projeto" ? "checked" : "unchecked"
                    }`}
                  >
                    Projeto
                  </div>
                </div>
              </div>
            )}

            {/* Form Respondido */}
            {filter === "Orçamento" && formState.answered && (
              <div className="frame-2-orc">
                <div className="contato">
                  <div className="text-wrapper-3-orc">Valores</div>
                  <div className="frame-3-orc">
                    <div className="frame-4-orc">
                      <div className="text-wrapper-4-orc">Valor total</div>
                      <div className="text-wrapper-5-orc">
                        {formState.valor.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </div>
                    </div>
                    <div className="frame-4-orc">
                      <div className="text-wrapper-4-orc">Valor do sinal</div>
                      <div className="text-wrapper-5-orc">
                        {formState.sinal.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contato">
                  <div className="text-wrapper-3-orc">Tempo e sessões</div>
                  <div className="frame-3-orc">
                    <div className="frame-4-orc">
                      <div className="text-wrapper-4-orc">Tempo total</div>
                      <div className="text-wrapper-5-orc">{`${new Date(
                        formState.tempo
                      )
                        .getHours()
                        .toString()
                        .padStart(2, "0")}h${new Date(formState.tempo)
                        .getMinutes()
                        .toString()
                        .padStart(2, "0")}m`}</div>
                    </div>
                    <div className="frame-4-orc">
                      <div className="text-wrapper-4-orc">Sessões</div>
                      <div className="text-wrapper-5-orc">
                        {formState.sessoes}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contato">
                  <div className="text-wrapper-3-orc">Agendamento sugerido</div>
                  <div className="frame-3-orc">
                    <div className="frame-4-orc">
                      <div className="text-wrapper-4-orc">Dia</div>
                      <div className="text-wrapper-5-orc">
                        {new Date(formState.agendamento).toLocaleDateString(
                          "pt-BR"
                        )}
                      </div>
                    </div>
                    <div className="frame-4-orc">
                      <div className="text-wrapper-4-orc">Horário</div>
                      <div className="text-wrapper-5-orc">{`${new Date(
                        formState.agendamento
                      )
                        .getHours()
                        .toString()
                        .padStart(2, "0")}h${new Date(formState.agendamento)
                        .getMinutes()
                        .toString()
                        .padStart(2, "0")}m`}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Form Sem Resposta */}
            {(filter === "Projeto" || !formState.answered) && (
              <div className="frame-3">
                <div className="div-2">
                  <div className="text-wrapper-4">Dados do cliente</div>
                  <div className="frame-4">
                    <div className="frame-5">
                      <div className="text-wrapper-5">Nome</div>
                      <div className="text-wrapper-6">
                        {formState.form?.name}
                      </div>
                    </div>
                    <div className="frame-5">
                      <div className="text-wrapper-5">Telefone</div>
                      <div className="text-wrapper-6">
                        {formState.form?.phone}
                      </div>
                    </div>
                    <div className="frame-5">
                      <div className="text-wrapper-5">E-mail</div>
                      <div className="text-wrapper-6">
                        {formState.form?.email}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="div-2">
                  <div className="text-wrapper-4">Tipo</div>
                  <div className="frame-6">
                    <div className="div-wrapper">
                      <div className="text-wrapper-8">{`${formState.tipo.name} `}</div>
                    </div>
                  </div>
                </div>

                {formState.regiao && (
                  <div className="div-2">
                    <div className="text-wrapper-4">Local da tattoo</div>
                    <div className="frame-6">
                      <div className="frame-7">
                        {formState.regiao?.img && (
                          <img
                            className="img"
                            alt="Frame"
                            src={formState.regiao?.img}
                          />
                        )}

                        <div className="text-wrapper-7">
                          {formState.regiao?.name}
                        </div>
                      </div>
                      <div className="frame-7">
                        {formState.local?.img && (
                          <img
                            className="img"
                            alt="Frame"
                            src={formState.local?.img}
                          />
                        )}

                        <div className="text-wrapper-7">
                          {formState.local?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {formState.tamanho && (
                  <div className="div-2">
                    <div className="text-wrapper-4">Tamanho</div>
                    <div className="frame-6">
                      <div className="div-wrapper">
                        <div className="text-wrapper-8">{`${formState.tamanho?.name}: ${formState.tamanho?.description}`}</div>
                      </div>
                    </div>
                  </div>
                )}

                {formState.cor && (
                  <div className="div-2">
                    <div className="text-wrapper-4">Cores</div>
                    <div className="frame-6">
                      <div className="div-wrapper">
                        <div className="text-wrapper-8">
                          {formState.cor?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {formState.estilo && (
                  <div className="div-2">
                    <div className="text-wrapper-4">Estilo</div>
                    <div className="frame-6">
                      <div className="div-wrapper">
                        <div className="text-wrapper-8">
                          {formState.estilo?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {formState.anexos && formState.anexos.length > 0 && (
                  <div className="div-2">
                    <div className="text-wrapper-4">Referências</div>
                    <div className="frame-8">
                      {formState.anexos?.map((anexo, index) => (
                        <img
                          key={index}
                          className="image"
                          alt="icon"
                          src={anexo.base64}
                          onClick={() => openModal(anexo.base64)}
                        />
                      ))}
                    </div>
                  </div>
                )}

                {formState.fotos && formState.fotos.length > 0 && (
                  <div className="div-2">
                    <div className="text-wrapper-4">Fotos do local</div>
                    <div className="frame-8">
                      {formState.fotos?.map((foto, index) => (
                        <img
                          key={index}
                          className="image"
                          alt="icon"
                          src={foto.base64}
                          onClick={() => openModal(foto.base64)}
                        />
                      ))}
                    </div>
                  </div>
                )}

                {formState.detalhes && (
                  <div className="div-2">
                    <div className="text-wrapper-4">Descrição</div>
                    <div className="frame-6">
                      <div className="div-wrapper">
                        <div className="text-wrapper-8">
                          {formState.detalhes}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {!formState.answered ? (
          <div className="frame-9">
            <div className="frame-10" onClick={handleSubmit}>
              <div className="text-wrapper-9">Responder orçamento</div>
            </div>
            <div className="frame-11" onClick={callClient}>
              <div className="text-wrapper-10">Chamar no Whatsapp</div>
            </div>
          </div>
        ) : (
          <div className="frame-9">
            <div className="frame-10" onClick={callClient}>
              <div className="text-wrapper-9">Falar com o cliente</div>
            </div>
          </div>
        )}
      </div>
      {modalVisible && (
        <Modal show={modalVisible} onClose={() => setModalVisible(false)}>
          <img
            src={selectedImage}
            alt="Imagem em tamanho original"
            className="image-modal-referencia"
          />
        </Modal>
      )}
    </div>
  );
};

export default Form;
