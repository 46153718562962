import React, { useContext, useState, useEffect } from "react";
import "./Agendamento.css";
import { FormContext } from "./FormContext";
import { FormHeader } from "./FormHeader";
import { FormFooter } from "./FormFooter";
import { useNavigate } from "react-router-dom";

import { useFormStateValidation } from "./FormStateValidation";

export const Agendamento = () => {
  useFormStateValidation();

  const { formState, updateForm } = useContext(FormContext);
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [horario, setHorario] = useState("");

  useEffect(() => {
    const fetchForm = () => {
      if (formState.data?.agendamento) {
        const agendamento = formState.data.agendamento;
        const ano = agendamento.getFullYear();
        const mes = (agendamento.getMonth() + 1).toString().padStart(2, "0"); // getMonth() retorna 0-11
        const dia = agendamento.getDate().toString().padStart(2, "0");
        const dataFormatada = `${ano}-${mes}-${dia}`; // Formato esperado pelo input type="date"
        setData(dataFormatada);
  
        const hours = agendamento.getHours().toString().padStart(2, "0");
        const minutes = agendamento.getMinutes().toString().padStart(2, "0");
        setHorario(`${hours}:${minutes}`); // Seta apenas horas e minutos
      } else {
        setData("");
        setHorario("");
      }
    };
    fetchForm();
  }, [formState.data?.agendamento]);

  const handleSubmit = () => {
    const agendamento = new Date(`${data}T${horario}`);
    updateForm({
      ...formState,
      data: {
        ...formState.data,
        agendamento,
      },
    });
    navigate("../mensagem");
  };

  return (
    <div>
      <FormHeader
        rightText={"passo 4/5"}
        midText={"Enviar Orçamento"}
        title={`Gostaria de sugerir um dia e horário para agendamento?`}
      />
      <div className="body-agendamento">
        <div className="frame-3">
          <div className="text-wrapper-2">Dia</div>
          <div className="frame-4">
            <input
              className="text-wrapper-3"
              type="date"
              placeholder="Selecione"
              value={data}
              onChange={(e) => setData(e.target.value)}
            />
          </div>
        </div>
        <div className="frame-3">
          <div className="text-wrapper-2">Horário</div>
          <div className="frame-5">
            <div className="frame-6">
              <input
                className="text-wrapper-4"
                type="time"
                placeholder="Selecione"
                value={horario}
                onChange={(e) => setHorario(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <FormFooter type={"active"} onClick={handleSubmit} />
    </div>
  );
};

export default Agendamento;
