import React, { useEffect, useState, useRef } from "react";
import { statusWpp } from "../../api/api";
function Whatsapp() {
  const [status, setStatus] = useState(null);
  const [isPolling, setIsPolling] = useState(true);
  const timeoutRef = useRef(null);

  const fetchStatus = async () => {
    try{
      const resultado = await statusWpp();
    setStatus(resultado);
    }catch(error){
      console.log(error)
    }
    
  };

  useEffect(() => {
    const pollStatus = async () => {
      await fetchStatus();
      if (status?.status !== 'conectado') {
        timeoutRef.current = setTimeout(pollStatus, 5000);
      } else {
        setIsPolling(false);
      }
    };

    if (isPolling) {
      pollStatus();
    }

    // Cleanup function to clear the timeout when the component unmounts
    return () => clearTimeout(timeoutRef.current);
    // eslint-disable-next-line
  }, [isPolling]);

  // Additional useEffect to start polling when status changes to a non-conectado state
  useEffect(() => {
    if (status?.status !== 'conectado' && !isPolling) {
      setIsPolling(true);
    }
    // eslint-disable-next-line
  }, [status]);

  return (
    <div className="content">
      <h1>Whatsapp</h1>
      <div>Status: {status?.status}</div>
      <div>
        {status?.status === "aguardando" && status?.qrcode && (
          <img alt="qrcode" src={status.qrcode} />
        )}
      </div>
    </div>
  );
}

export default Whatsapp;
