import React, { useEffect, useState } from "react";

import { resumoHome } from "../../api/api";
function Home() {
  const [resumo, setResumo] = useState([]);

  useEffect(() => {
    const fetchDados = async () => {
      const result = await resumoHome();
      setResumo(result);
    };

    fetchDados();
  }, []);
  
  const styleTH = {
    padding: "12px",
    border: "1px solid #ddd",
    textAlign: "left",
  }

  const styleTD = {
    padding: "12px", 
    border: "1px solid #ddd" 
  }
  return (
    <div className="content">
      <h1>Home</h1>
      <div>
        <h2>Dados dos Artistas</h2>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ backgroundColor: "#f4f4f4" }}>
              <th
                style={styleTH}
              >
                Nome (Alias)
              </th>
              <th
                style={styleTH}
              >
                Total
              </th>
              <th
                style={styleTH}
              >
                Completos
              </th>
              <th
                style={styleTH}
              >
                Incompletos
              </th>
              <th
                style={styleTH}
              >
                Respondidos
              </th>
              <th
                style={styleTH}
              >
                Notificados
              </th>
            </tr>
          </thead>
          <tbody>
            {resumo.map((artista) => (
              <tr key={artista._id}>
                <td
                  style={styleTD}
                >{`${artista.name} (${artista.alias})`}</td>
                <td style={styleTD}>
                  {artista.total}
                </td>
                <td style={styleTD}>
                  {artista.completos}
                </td>
                <td style={styleTD}>
                  {artista.incompletos}
                </td>
                <td style={styleTD}>
                  {artista.respondidos}
                </td>
                <td style={styleTD}>
                  {artista.notificados}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Home;
