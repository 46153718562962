import React, { useContext, useState, useEffect } from "react";
import "./Valor.css";
import { FormContext } from "./FormContext";
import { FormHeader } from "./FormHeader";
import { FormFooter } from "./FormFooter";
import { useNavigate } from "react-router-dom";

import formatCurrency from "../../../funcs/formatCurrency";

import { useFormStateValidation } from "./FormStateValidation";

export const Valor = () => {
  useFormStateValidation();

  const { formState, updateForm } = useContext(FormContext);
  const navigate = useNavigate();
  const [valor, setValor] = useState(0);

  useEffect(() => {
    const fetchForm = () => {
      formState.data?.valor
        ? setValor(formState.data?.valor * 100)
        : setValor(0);
    };
    fetchForm();
  }, [formState.data?.valor]);

  const handleInputChange = (event) => {
    const input = event.target;
    const inputValue = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
    const numericValue = parseInt(inputValue, 10);

    if (!isNaN(numericValue)) {
      setValor(numericValue);
      setTimeout(() => {
        // Define a posição do cursor para o final do valor atualizado
        input.selectionStart = input.selectionEnd = input.value.length;
      }, 0);
    }
  };

  const handleSubmit = () => {
    const valorDecimal = valor / 100;
    updateForm({
      ...formState,
      data: {
        ...formState.data,
        valor: valorDecimal,
      },
    });
    navigate("../sinal");
  };

  return (
    <div>
      <FormHeader
        rightText={"passo 1/5"}
        midText={"Enviar Orçamento"}
        title={`Qual o valor total do projeto para ${formState.form?.name}`}
      />
      <div className="body-valor">
        <div className="frame-3">
          <div className="text-wrapper-2">R$</div>
          <input
            type="text"
            inputMode="numeric"
            className="text-wrapper-3"
            value={formatCurrency(valor)}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <FormFooter type={valor ? "active" : "inactive"} onClick={handleSubmit} />
    </div>
  );
};

export default Valor;
