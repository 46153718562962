import React, {useContext} from "react";
import "./Final.css";
import { useNavigate } from "react-router-dom";
import { FormContext } from "./FormContext";

export const Final = () => {
    const { resetForm } = useContext(FormContext);
    const navigate = useNavigate();

    const goBack = () => {
      resetForm();
      navigate("/artist");
    }
  return (
    <div className="final-body">
      <div className="frame">
        <div className="mark-email-read-wrapper">
          <img className="mark-email-read" alt="Mark email read" src="/img/formartist/mark-email-read.png" />
        </div>
        <div className="text-wrapper">Orçamento enviado!</div>
      </div>
      <div className="div-wrapper2" onClick={goBack}>
        <div className="div">Voltar ao início</div>
      </div>
    </div>
  );
};

export default Final;
