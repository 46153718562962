import React, { useContext, useState, useEffect } from "react";
import { RadioButton } from "../../components/RadioButton";
import "./Cores.css";
import { FormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";

import { useFormStateValidation } from "./FormStateValidation";

export const Cores = () => {
  useFormStateValidation();
  
  const cores = [
    {
      id: 1,
      name: "Colorida",
    },
    {
      id: 2,
      name: "Preto e cinza",
    },
    {
      id: 0,
      name: "Ainda não decidi",
    },
  ];

  const { formState, updateForm } = useContext(FormContext);
  const navigate = useNavigate();
  const [corSelecionada, setCorSelecionada] = useState(null);

  useEffect(() => {
    const fetchForm = () => {
      formState.cor
        ? setCorSelecionada(formState.cor)
        : setCorSelecionada(null);
    };
    fetchForm();
  }, [formState.cor]);

  const handleCorClick = (cor) => {
    setCorSelecionada(cor.id === corSelecionada?.id ? null : cor);
  };

  const handleSubmit = () => {
    updateForm({
      ...formState,
      cor: corSelecionada,
    });
    navigate("../estilo");
  };

  return (
    <div>
      <FormHeader
        rightText={"passo 7/10"}
        title={"Qual é o esquema de cores?"}
      />

      <div className="body-cores">
        <div className="frame-3">
          {cores.map((cor) => (
            <div
              className={`frame-4 ${
                corSelecionada && corSelecionada.id === cor.id && "selected"
              }`}
              key={cor.id}
              onClick={() => handleCorClick(cor)}
            >
              <div className={`frame-5`}>
                <RadioButton
                  className="radio-button-instance"
                  state="default"
                  type={corSelecionada?.id === cor.id ? "checked" : "unchecked"}
                />
                <div className="frame-6">
                  <div className="frame-7">
                    <div className="text-wrapper-2">{cor.name}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <FormFooter
        onClick={handleSubmit}
        type={corSelecionada ? "active" : "inactive"}
      />
    </div>
  );
};

export default Cores;
