import React from "react";

export const Check = ({ className }) => {
  return (
    <svg
      className={`check-2 ${className}`}
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8.795 16.2031L5.325 12.7331C4.935 12.3431 4.305 12.3431 3.915 12.7331C3.525 13.1231 3.525 13.7531 3.915 14.1431L8.095 18.3231C8.485 18.7131 9.115 18.7131 9.505 18.3231L20.085 7.74307C20.475 7.35307 20.475 6.72307 20.085 6.33307C19.695 5.94307 19.065 5.94307 18.675 6.33307L8.795 16.2031Z"
        fill="white"
      />
    </svg>
  );
};

export default Check;