import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const Unauthorized = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/login');
    }, 5000);

    return () => clearTimeout(timer); // Limpa o timer se o componente for desmontado
  }, [navigate]);

  return (
    <div>
      <h1>Acesso não autorizado</h1>
      <p>Você não tem permissão para acessar esta página.</p>
      <p style={{ color: 'red' }}>Realize o <Link to="/login">login</Link> para continuar</p>
    </div>
  );
};

export default Unauthorized;