import React, { useContext, useEffect, useState } from "react";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "./Form.css";
import { FormContext } from "./FormContext";
import { getArtistAlias } from "../../../api/api";
import { toast } from "react-toastify";

function Form() {
  const navigate = useNavigate();
  const { formState, updateForm, resetForm } = useContext(FormContext);
  const [artist, setArtist] = useState(null);
  const { artistAlias } = useParams();
  const [mensagemPadrao, setMensagemPadrao] = useState("");

  useEffect(() => {
    const fetchArtist = async () => {
      try {
        const data = await getArtistAlias(artistAlias);
        setArtist(data);
        updateForm({ artista: data });
        setMensagemPadrao(`Opa!
        
Tudo certo?
        
Aqui é o ${data.nickname} e desde já agradeço por confiar no meu trabalho.
        
Para transferir a ideia que está na sua cabeça para a sua pele eu preciso de algumas informações.
        
Vamos juntos?`);
      } catch (error) {
        console.error(error);
        if (error.response) {
          // O pedido foi feito e o servidor respondeu com um status fora do intervalo de 2xx
          toast.error(error.response.data.msg);
          const timer = setTimeout(() => {
            navigate("/login");
          }, 3000);
          return () => clearTimeout(timer);
        } else if (error.request) {
          // O pedido foi feito, mas nenhuma resposta foi recebida
          toast.error(
            "Erro ao fazer a solicitação. Por favor, tente novamente."
          );
        } else {
          // Algo aconteceu na configuração do pedido que disparou um erro
          toast.error("Erro desconhecido. Por favor, tente novamente.");
        }
      }
    };

    fetchArtist();
    // eslint-disable-next-line
  }, []);

  const handleStartClick = () => {
    resetForm();
    updateForm({ ...formState, artistid: artist._id });
    navigate("./contact");
  };

  return (
    <div className="saudao">
      <div className="container">
        {artist && (
          <>
            <img
              className="rectangle"
              alt="Rectangle"
              src={formState?.artista?.fotocapa ? formState?.artista?.fotocapa : "/img/form/rectangle-1.png"}
            />
            <div className="gradient-overlay"></div>
          </>
        )}

        <div className="frame">
          {artist && (
            <img
              className="ellipse"
              alt="Ellipse"
              src={formState?.artista?.fotoperfil}
            />
          )}
        </div>
        {artist && (
          <div className="opa-tudo-certo-aqui-wrapper">
            <p className="opa-tudo-certo-aqui">
              {artist.text1 ? artist.text1 : mensagemPadrao}
            </p>
          </div>
        )}
        {!artist && (
          <div className="opa-tudo-certo-aqui-wrapper">
            <p className="opa-tudo-certo-aqui">Artista não encontrado</p>
          </div>
        )}
      </div>
      {artist && (
        <div className="frame-wrapper">
          <div className="div-wrapper" onClick={handleStartClick}>
            <div className="text-wrapper">Vamos!</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Form;
