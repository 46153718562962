import React, { useContext, useState } from "react";
import "./Detalhes.css";
import { FormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";
import { toast } from "react-toastify";

import { sendForm } from "../../../api/api";

import { useFormStateValidation } from "./FormStateValidation";

export const Detalhes = () => {
  useFormStateValidation();

  const { formState, updateForm, resetForm } = useContext(FormContext);
  const navigate = useNavigate();
  const [detalhesDigitado, setDetalhesDigitado] = useState("");

  const handleInputChange = (event) => {
    setDetalhesDigitado(event.target.value);
  };

  const handleSubmit = async () => {
    
    
    try{
      const data = await sendForm({
        ...formState,
        detalhes: detalhesDigitado,
        completed: true
      });
      toast.success(data.msg);
      resetForm()
      updateForm({artista: formState.artista})
      navigate("../final");
    } catch (error) {
      console.error(error);
      if (error.response.data.msg) {
        // O pedido foi feito e o servidor respondeu com um status fora do intervalo de 2xx
        toast.error(error.response.data.msg);
      } else if (error.request) {
        // O pedido foi feito, mas nenhuma resposta foi recebida
        toast.error(
          "Erro ao fazer a solicitação. Por favor, tente novamente."
        );
      } else {
        // Algo aconteceu na configuração do pedido que disparou um erro
        toast.error("Erro desconhecido. Por favor, tente novamente.");
      }
    }
    
    

  };

  return (
    <div>
      <FormHeader
        rightText={formState.tipo.id === 1 ? "passo 10/10" : "passo 3/3"}
        title={"Tem mais algum detalhe que gostaria de me informar?"}
      />
      <div className="body-detalhes">
        <p className="text-wrapper-2">
          Use esse espaço para descrever melhor sua ideia
        </p>
        <div className="frame-3">
          <textarea
            className="text-wrapper-3"
            placeholder="Descreva sua ideia aqui"
            value={detalhesDigitado}
            onChange={handleInputChange}
          ></textarea>
        </div>
      </div>
      <FormFooter text={'Finalizar'} onClick={handleSubmit} type={"active"} />
    </div>
  );
};

export default Detalhes;
