import React, { useEffect, useState } from 'react';
import DropdownList from './Components/DropDownList';
import Table from './Components/Table';

import { getLocais, locaisUpdate } from '../../api/api';



function App() {
  const [selectedRegiao, setSelectedRegiao] = useState(null);
  const [locais, setLocais] = useState([])

  useEffect(() => {
    const fetchLocais = async () => {
      const result = await getLocais()
      setLocais(result)
    }
    fetchLocais()
  },[])

  

  const handleSave = async (updatedLocais) => {
    const newLocal = { ...selectedRegiao, locais: updatedLocais }
    const result = await locaisUpdate(newLocal)
    result && window.alert('atualizado')
    console.log(result)
  };

  return (
    <div className="content">
      <h1>Selecione uma Região</h1>
      <DropdownList
        regiaoData={locais}
        onSelect={setSelectedRegiao}
      />
      {selectedRegiao && (
        <Table
          locais={selectedRegiao.locais}
          onSave={handleSave}
        />
      )}
    </div>
  );
}

export default App;
