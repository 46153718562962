import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const RadioButton = ({ showLabel = false, radioButtonLabel = "Radio button label", type, state, className }) => {
  return (
    <div className={`radio-button ${className}`}>
      <div className={`frame ${type} ${state}`}>{type === "checked" && <div className="ellipse" />}</div>
    </div>
  );
};

RadioButton.propTypes = {
  showLabel: PropTypes.bool,
  radioButtonLabel: PropTypes.string,
  type: PropTypes.oneOf(["checked", "unchecked"]),
  state: PropTypes.oneOf(["disable", "hover", "default"]),
};
