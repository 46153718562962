import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormContext } from './FormContext';

export const useFormStateValidation = () => {
  const { formState } = useContext(FormContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica se o objeto formState está vazio
    if (Object.keys(formState).length === 0) {
      navigate('/artist'); // Altere para a rota correta se necessário
      return
    }
  }, [formState, navigate]);
};