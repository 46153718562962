import axios from "axios";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const loginUser = async (email, password) => {
  const response = await api.post("/login", { email, password });
  return response.data;
};

export const logout = () => {
  localStorage.clear();
  window.location.href = "/login";
};

export const checkToken = async () => {
  const token = localStorage.getItem("token");
  const response = await api.get("/verifytoken", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const getRegioes = async () => {
  const token = localStorage.getItem("token");
  const response = await api.get("/regioes", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const getLocais = async () => {
  const token = localStorage.getItem("token");
  const response = await api.get("/locais", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const getEstilos = async () => {
  const token = localStorage.getItem("token");
  const response = await api.get("/estilos", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const sendUser = async (form) => {
  const response = await api.post("/user", form);
  return response.data;
};

export const getArtists = async () => {
  const token = localStorage.getItem("token");
  const response = await api.get("/artists", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const getArtist = async (_id) => {
  const token = localStorage.getItem("token");
  const response = await api.post(
    "/artist",
    { _id },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

export const updatePassword = async (_id, password,) => {
  const token = localStorage.getItem("token");
  const response = await api.post(
    "/updatepassword",
    { _id, password },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

export const getArtistAlias = async (alias) => {
  const token = localStorage.getItem("token");
  const response = await api.post(
    "/artist",
    { alias },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

export const sendForm = async (form) => {
  const token = localStorage.getItem("token");
  const response = await api.post("/sendform", form, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const getForms = async (filter) => {
  const token = localStorage.getItem("token");
  try {
    const response = await api.post("/getforms", filter, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar no banco", error);
    if (error.response) {
      // O pedido foi feito e o servidor respondeu com um status fora do intervalo de 2xx
      toast.error(error.response.data.msg);
    } else if (error.request) {
      // O pedido foi feito, mas nenhuma resposta foi recebida
      toast.error("Erro ao fazer o pedido. Por favor, tente novamente.");
    } else {
      // Algo aconteceu na configuração do pedido que disparou um erro
      toast.error("Erro desconhecido. Por favor, tente novamente.");
    }
  }
};

export const getFullForm = async (form) => {
  const token = localStorage.getItem("token");
  const response = await api.post("/getfullform", form, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const updateOneForm = async (form) => {
  const token = localStorage.getItem("token");
  const response = await api.post("/updateform", form, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};


// ------------------- Admex

export const resumoHome = async () => {
  const token = localStorage.getItem("token");
  const response = await api.post("/resumohome", null, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const locaisUpdate = async (form) => {
  const token = localStorage.getItem("token");
  const response = await api.post("/locaisupdate", form, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};


// ------------------- Whatsapp

export const statusWpp = async () => {
  const response = await api.get("/statuswpp");
  return response.data;
};


//------------------- Teste


export const teste = async () => {
  const response = await api.get("/teste");
  return response.data;
};
