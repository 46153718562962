import React, { useEffect, useState } from "react";
import PhoneMasked from "../components/PhoneMasked/PhoneMasked";
import { getRegioes, getEstilos ,sendUser } from "../../api/api";
import { RadioButton } from "../components/RadioButton";
import "./styles.css";
import "./Usuarios.css";
import Check from "../SVG/Check";
import { toast } from "react-toastify";
import Modal from "../components/Modal/Modal";

const emptyForm = {
  name: "",
  nickname: "",
  alias: "",
  phone: "",
  email: "",
  password: "temp367",
  confirmpassword: "temp367",
  text1: "",
  text2: "",
  text3: "",
  regioes: [],
  estilos: [],
  fotoperfil: null,
  fotocapa: null,

  active: true,
  firstlogin: true,
  role: "artist",
};

function Usuarios() {
  const [addUser, setAddUser] = useState(false);
  const [form, setForm] = useState(emptyForm);
  const [errors, setErrors] = useState({});
  const [regioes, setRegioes] = useState([]);
  const [estilos, setEstilos] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const fetchRegioes = async () => {
      const resultado = await getRegioes();
      setRegioes(resultado);
    };
    fetchRegioes();
  }, []);

  useEffect(() => {
    const fetchEstilos = async () => {
      const resultado = await getEstilos();
      setEstilos(resultado);
    };
    fetchEstilos();
  }, []);

  const handleCancel = () => {
    setForm(emptyForm);
    setAddUser(false);
  };

  const handleSave = async () => {
    const newErrors = {};

    if (!form.name || form.name.length < 2) {
      newErrors.name = "Nome deve ter mais de 1 letra";
    }

    if (!form.nickname || form.nickname.length < 2) {
      newErrors.nickname = "Apelido deve ter mais de 1 letra";
    }

    if (!form.alias || form.alias.length < 2) {
      newErrors.alias = "Alias deve ter mais de 1 letra";
    }

    if (!form.phone || !/^\d{10,11}$/.test(form.phone.replace(/[^0-9]/g, ""))) {
      newErrors.phone = "Telefone deve ter 10 ou 11 dígitos";
    }

    if (!form.email || !/\S+@\S+\.\S+/.test(form.email)) {
      newErrors.email = "E-mail inválido";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    await sendUser(form)
    
    setForm(emptyForm);
    setAddUser(false);
  };

  const handleChange = (e, inputName) => {
    let name, value;

    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = inputName;
      value = e;
    }

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleRegiaoClick = (regiao) => {
    setForm((prevForm) => {
      // Verifica se a região já está no array
      const isSelected = prevForm.regioes.some(
        (selected) => selected._id === regiao._id
      );
      if (isSelected) {
        // Remove a região se já estiver no array
        return {
          ...prevForm,
          regioes: prevForm.regioes.filter(
            (selected) => selected._id !== regiao._id
          ),
        };
      } else {
        // Adiciona a região se não estiver no array
        return { ...prevForm, regioes: [...prevForm.regioes, regiao] };
      }
    });
  };

  const handleEstiloClick = (estilo) => {
    setForm((prevForm) => {
      // Verifica se a região já está no array
      const isSelected = prevForm.estilos.some(
        (selected) => selected._id === estilo._id
      );
      if (isSelected) {
        // Remove a região se já estiver no array
        return {
          ...prevForm,
          estilos: prevForm.estilos.filter(
            (selected) => selected._id !== estilo._id
          ),
        };
      } else {
        // Adiciona a região se não estiver no array
        return { ...prevForm, estilos: [...prevForm.estilos, estilo] };
      }
    });
  };

  const removeAnexo = (atributo) => {
    setForm({ ...form, [atributo]: null });
  };

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalVisible(true);
  };

  const anexarFoto = (atributo) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/jpeg, image/png";
    input.multiple = false; // Permitir apenas um arquivo
    input.click();

    input.addEventListener("change", async (event) => {
      const file = event.target.files[0]; // Pega o único arquivo selecionado

      if (!file) return; // Se nenhum arquivo foi selecionado, saia da função

      if (!["image/jpeg", "image/png"].includes(file.type)) {
        toast.error("Apenas arquivos JPG e PNG são permitidos");
        return;
      }

      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const img = new Image();
          img.onload = () => {
            let width = img.width;
            let height = img.height;
            const maxWidth = 1920;
            const maxHeight = 1080;

            if (width > maxWidth || height > maxHeight) {
              const ratio = Math.min(maxWidth / width, maxHeight / height);
              width = width * ratio;
              height = height * ratio;
            }

            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            const imageBase64 = canvas.toDataURL("image/jpeg");

            setForm((prevForm) => ({
              ...prevForm,
              [atributo]: imageBase64,
            }));
          };
          img.onerror = (error) => {
            toast.error("Erro ao carregar a imagem");
            console.error("Erro ao carregar a imagem:", error);
          };
          img.src = e.target.result;
        };
        reader.onerror = (error) => {
          toast.error("Erro ao ler o arquivo");
          console.error("Erro ao ler o arquivo:", error);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        toast.error("Erro ao processar a imagem");
        console.error("Erro ao processar a imagem:", error);
      }
    });
  };

  return (
    <div className="content">
      <h1>Usuários</h1>
      <div className="frame-1">
        {!addUser && (
          <button onClick={() => setAddUser(true)}>Adicionar</button>
        )}
      </div>

      {addUser && (
        <div>
          <div className="form-usuario">
            <div className="frame-1">
              <div className="form-text-title">Nome</div>
              <div className="frame-2">
                <input
                  className={`form-text-field ${errors.name ? "error" : ""}`}
                  type="text"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                  placeholder="Nome"
                />
                {errors.name && (
                  <div className="form-text-field-error-message">
                    {errors.name}
                  </div>
                )}
              </div>
            </div>

            <div className="frame-1">
              <div className="form-text-title">Apelido</div>
              <div className="frame-2">
                <input
                  className={`form-text-field ${
                    errors.nickname ? "error" : ""
                  }`}
                  type="text"
                  name="nickname"
                  value={form.nickname}
                  onChange={handleChange}
                  placeholder="Apelido"
                />
                {errors.nickname && (
                  <div className="form-text-field-error-message">
                    {errors.nickname}
                  </div>
                )}
              </div>
            </div>

            <div className="frame-1">
              <div className="form-text-title">Alias</div>
              <div className="frame-2">
                <input
                  className={`form-text-field ${errors.alias ? "error" : ""}`}
                  type="text"
                  name="alias"
                  value={form.alias}
                  onChange={handleChange}
                  placeholder="/alias"
                />
                {errors.alias && (
                  <div className="form-text-field-error-message">
                    {errors.alias}
                  </div>
                )}
              </div>
            </div>

            <div className="frame-1">
              <div className="form-text-title">Telefone</div>
              <div className="frame-2">
                <PhoneMasked
                  value={form.phone}
                  name={"phone"}
                  onChange={(value) => handleChange(value, "phone")}
                  className={`form-text-field ${errors.phone ? "error" : ""}`}
                  placeholder="(00) 00000-0000"
                />
                {errors.phone && (
                  <div className="form-text-field-error-message">
                    {errors.phone}
                  </div>
                )}
              </div>
            </div>

            <div className="frame-1">
              <div className="form-text-title">E-mail</div>
              <div className="frame-2">
                <input
                  className={`form-text-field ${errors.email ? "error" : ""}`}
                  type="text"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  placeholder="exemplo@email.com"
                />
                {errors.email && (
                  <div className="form-text-field-error-message">
                    {errors.email}
                  </div>
                )}
              </div>
            </div>

            <div className="frame-1">
              <div className="form-text-title">Texto Saudação</div>
              <div className="frame-2">
                <textarea
                  className={`form-text-field ${errors.text1 ? "error" : ""}`}
                  type="text"
                  name="text1"
                  value={form.text1}
                  onChange={handleChange}
                  placeholder="Olá..."
                />
                {errors.text1 && (
                  <div className="form-text-field-error-message">
                    {errors.text1}
                  </div>
                )}
              </div>
            </div>

            <div className="frame-1">
              <div className="form-text-title">Texto Final Orçamento Cliente</div>
              <div className="frame-2">
                <textarea
                  className={`form-text-field ${errors.text2 ? "error" : ""}`}
                  type="text"
                  name="text2"
                  value={form.text2}
                  onChange={handleChange}
                  placeholder="Olá..."
                />
                {errors.text2 && (
                  <div className="form-text-field-error-message">
                    {errors.text2}
                  </div>
                )}
              </div>
            </div>

            <div className="frame-1">
              <div className="form-text-title">Texto Envio Orçamento</div>
              <div className="frame-2">
                <textarea
                  className={`form-text-field ${errors.text3 ? "error" : ""}`}
                  type="text"
                  name="text3"
                  value={form.text3}
                  onChange={handleChange}
                  placeholder="Olá..."
                />
                {errors.text3 && (
                  <div className="form-text-field-error-message">
                    {errors.text3}
                  </div>
                )}
              </div>
            </div>

            <div className="frame-1">
              <p>Regiões Habilitadas</p>
              <div className="frame-4">
                {regioes.map((regiao) => (
                  <div className={`frame-5`} key={regiao._id}>
                    <img
                      className={`frame-6 ${
                        form.regioes.some(
                          (selected) => selected._id === regiao._id
                        )
                          ? "selected"
                          : "not-selected"
                      }`}
                      alt="Frame"
                      src={regiao.img}
                      onClick={() => handleRegiaoClick(regiao)}
                    />
                    <div className="text-wrapper-2">{regiao.name}</div>
                    {form.regioes.some(
                      (selected) => selected._id === regiao._id
                    ) && <Check className="check-icon2" />}
                  </div>
                ))}
              </div>
            </div>

            <div className="frame-1">
              <p>Estilos Selecionados</p>

              <div className="frame-4-estilo">
                {estilos.map((estilo) => (
                  <div
                    className={`frame-5-estilo`}
                    key={estilo._id}
                    onClick={() => handleEstiloClick(estilo)}
                  >
                    <div
                      className={`frame-6-estilo${
                        form.estilos.some(
                          (selected) => selected._id === estilo._id
                        )
                          ? "-checked"
                          : ""
                      }`}
                      style={{ backgroundImage: `url(${estilo.img})` }}
                    >
                      <div className="frame-7-estilo">
                        <div
                          className={`text-wrapper-2-estilo${
                            ["Ornamental", "Oldschool"].includes(estilo.name)
                              ? "-black"
                              : ""
                          }`}
                        >
                          {estilo.name}
                        </div>
                        <RadioButton
                          className="radio-button-instance-estilo"
                          state="default"
                          type={
                            form.estilos.some(
                              (selected) => selected._id === estilo._id
                            )
                              ? "checked"
                              : "unchecked"
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="frame-1">
              <p>Foto Perfil</p>
              {form.fotoperfil && (
                <div className="frame-1-perfil">
                  <div className="frame-2-perfil">
                    <div className="image-container-perfil">
                      <img
                        className="image-square-perfil"
                        src={form.fotoperfil}
                        alt={`Foto Perfil`}
                        onClick={() => openModal(form.fotoperfil)}
                      />
                      <div
                        className="delete-icon-perfil"
                        onClick={() => removeAnexo("fotoperfil")}
                      >
                        <img src={"/img/form/remove.svg"} alt="Delete icon" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!form.fotoperfil && (
                <div
                  className="frame-3-perfil"
                  onClick={() => anexarFoto("fotoperfil")}
                >
                  <img
                    className="add-photo-alternate-perfil"
                    alt="Add icon alternate"
                    src="/img/form/referencia/add-photo-alternate.png"
                  />
                  <p className="text-wrapper-3-perfil">
                    Clique aqui anexar a foto
                  </p>
                </div>
              )}
            </div>

            <div className="frame-1">
              <p>Foto Capa</p>
              {form.fotocapa && (
                <div className="frame-1-perfil">
                  <div className="frame-2-perfil">
                    <div className="image-container-perfil">
                      <img
                        className="image-square-perfil"
                        src={form.fotocapa}
                        alt={`Foto Perfil`}
                        onClick={() => openModal(form.fotocapa)}
                      />
                      <div
                        className="delete-icon-perfil"
                        onClick={() => removeAnexo("fotocapa")}
                      >
                        <img src={"/img/form/remove.svg"} alt="Delete icon" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!form.fotocapa && (
                <div
                  className="frame-3-perfil"
                  onClick={() => anexarFoto("fotocapa")}
                >
                  <img
                    className="add-photo-alternate-perfil"
                    alt="Add icon alternate"
                    src="/img/form/referencia/add-photo-alternate.png"
                  />
                  <p className="text-wrapper-3-perfil">
                    Clique aqui anexar a foto
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="footer">
            <button onClick={handleCancel}>Cancelar</button>
            <button onClick={handleSave}>Salvar</button>
          </div>

          {modalVisible && (
            <Modal show={modalVisible} onClose={() => setModalVisible(false)}>
              <img
                src={selectedImage}
                alt="Imagem em tamanho original"
                className="image-modal-referencia-perfil"
              />
            </Modal>
          )}
        </div>
      )}
    </div>
  );
}

export default Usuarios;
