import React from "react";
import "./FormHeader.css";

export const FormHeader = ({ midText, rightText, title }) => {
  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="form-header">
      <div className="header">
        <div className="botao-goback" onClick={goBack}>
          <img className="img" alt="Frame" src="/img/form/back-svg.svg" />
        </div>
        <div className="text-wrapper">{midText ? midText : "Projeto"}</div>
        <div className="div">{rightText}</div>
      </div>
      <div className="div-wrapper">
        <div className="frame-2">
          <p className="p">{title}</p>
        </div>
      </div>
    </div>
  );
};

export default FormHeader;
