import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Contact.css";
import { FormContext } from "./FormContext";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";
import PhoneMasked from "../../components/PhoneMasked/PhoneMasked";
import { useFormStateValidation } from "./FormStateValidation";
// import ModalLoading from "../../components/Modal/ModalLoading";

import { sendForm } from "../../../api/api";

export const Contact = () => {
  useFormStateValidation();

  const navigate = useNavigate();
  const { formState, updateForm } = useContext(FormContext);
  const [form, setForm] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  // const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    const fetchForm = () => {
      formState.form
        ? setForm(formState.form)
        : setForm({
            name: "",
            phone: "",
            email: "",
          });
    };
    fetchForm();
  }, [formState.form]);

  // abandono de carrinho
  useEffect(() => {
    const criaForm = async () => {
      if (
        !formState._id &&
        form &&
        form.name &&
        form.phone &&
        form.phone.length >= 4 &&
        (form.phone.substr(2, 1) === "9"
          ? form.phone.length === 11
          : form.phone.length === 10)
      ) {
        // setModalLoading(true);
        const newForm = await sendForm({
          ...formState,
          form,
          completed: false,
          notified: false,
        });
        updateForm(newForm.form);
        // setModalLoading(false);
      }
      if (formState._id && form && (form.phone.substr(2, 1) === "9"
      ? form.phone.length === 11
      : form.phone.length === 10) && (form.phone !== formState.form.phone)) {
        // setModalLoading(true);
        const newForm = await sendForm({
          ...formState,
          form
        });
        updateForm(newForm.form);
        // setModalLoading(false);
      }
      
    };

    criaForm();
    // eslint-disable-next-line
  }, [form]);

  const handleChange = (e, inputName) => {
    let name, value;

    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = inputName;
      value = e;
    }

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const newErrors = {};

    if (!form.name || form.name.length < 2) {
      newErrors.name = "Nome deve ter mais de 1 letra";
    }

    if (!form.phone || !/^\d{10,11}$/.test(form.phone.replace(/[^0-9]/g, ""))) {
      newErrors.phone = "Telefone deve ter 10 ou 11 dígitos";
    }

    if (!form.email || !/\S+@\S+\.\S+/.test(form.email)) {
      newErrors.email = "E-mail inválido";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    updateForm({
      ...formState,
      form,
    });
    navigate("../cobertura");
  };

  /*
  const handleAbandonCart = () => {
    if (form.name || form.phone) {
      teste();
    }
  };

  useEffect(() => {
    

    const handleUnload = () => {
      handleAbandonCart();
    };

    
    window.addEventListener("unload", handleUnload);

    return () => {
      
      window.removeEventListener("unload", handleUnload);
    };
  // eslint-disable-next-line
  }, [form]);
  */
  return (
    <div>
      <FormHeader
        rightText={""}
        title={"Qual é o seu nome, e-mail e número de telefone?"}
      />

      <div className="body-contact">
        <div className="frame-3">
          <div className="text-wrapper-2">Nome</div>
          <div className="frame-4">
            <input
              className={`text-wrapper-3 ${errors.name ? "error" : ""}`}
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder="Nome"
            />
            {errors.name && <div className="error-message">{errors.name}</div>}
          </div>
        </div>
        <div className="frame-3">
          <div className="text-wrapper-2">Telefone</div>
          <div className="frame-4">
            <PhoneMasked
              value={form.phone}
              name={"phone"}
              onChange={(value) => handleChange(value, "phone")}
              className={`text-wrapper-3 ${errors.phone ? "error" : ""}`}
              placeholder="(00) 00000-0000"
            />
            {errors.phone && (
              <div className="error-message">{errors.phone}</div>
            )}
          </div>
        </div>
        <div className="frame-3">
          <div className="text-wrapper-2">E-mail</div>
          <div className="frame-4">
            <input
              className={`text-wrapper-3 ${errors.email ? "error" : ""}`}
              type="text"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder="exemplo@email.com"
            />
            {errors.email && (
              <div className="error-message">{errors.email}</div>
            )}
          </div>
        </div>
      </div>

      <FormFooter
        type={form.name && form.phone && form.email ? "active" : "inactive"}
        onClick={handleSubmit}
      />

      {/* modalLoading && (
        <ModalLoading
          open={modalLoading}
          onClose={() => setModalLoading(false)}
        />
      ) */}
    </div>
  );
};

export default Contact;
