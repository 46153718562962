import React, { useContext, useState, useEffect } from "react";
import "./Tempo.css";
import { FormContext } from "./FormContext";
import { FormHeader } from "./FormHeader";
import { FormFooter } from "./FormFooter";
import { useNavigate } from "react-router-dom";

import { useFormStateValidation } from "./FormStateValidation";

const tempos = [
  "00:30",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
];

export const Tempo = () => {
  useFormStateValidation();

  const { formState, updateForm } = useContext(FormContext);
  const navigate = useNavigate();
  const [tempo, setTempo] = useState("");
  const [sessoes, setSessoes] = useState("");

  useEffect(() => {
    const fetchForm = () => {
      if (formState.data?.tempo) {
        // formState.data.tempo é um objeto Date
        const hours = formState.data.tempo
          .getHours()
          .toString()
          .padStart(2, "0");
        const minutes = formState.data.tempo
          .getMinutes()
          .toString()
          .padStart(2, "0");
        setTempo(`${hours}:${minutes}`); // Seta apenas horas e minutos
      } else {
        setTempo("");
      }
    };
    fetchForm();
  }, [formState.data?.tempo]);

  useEffect(() => {
    const fetchForm = () => {
      formState.data?.sessoes
        ? setSessoes(formState.data?.sessoes)
        : setSessoes("");
    };
    fetchForm();
  }, [formState.data?.sessoes]);

  const handleTimeChange = (e) => {
    setTempo(e.target.value);
  };

  const handleSubmit = () => {
    // converter para o tipo data para salvar no banco, pois o tipo time não é suportado e date é melhor para calcular horas
    const date = new Date(`1970-01-01T${tempo}`);
    updateForm({
      ...formState,
      data: {
        ...formState.data,
        tempo: date,
        sessoes: sessoes,
      },
    });
    navigate("../agendamento");
  };

  return (
    <div>
      <FormHeader
        rightText={"passo 3/5"}
        midText={"Enviar Orçamento"}
        title={`Quanto tempo e quantas sessões levaria para fazer a tatuagem?`}
      />
      <div className="body-tempo">
        <div className="frame-3">
          <div className="text-wrapper-2">Tempo total</div>
          <div className="frame-4">
            <div className="frame-5">
              <select
                className="text-wrapper-3"
                value={tempo}
                onChange={handleTimeChange}
              >
                <option value="">Selecione</option>
                {tempos.map((tempo, index) => {
                  return (
                    <option key={index} value={tempo}>
                      {tempo}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="frame-3">
          <div className="text-wrapper-2">Sessões</div>
          <div className="frame-6">
            <input
              className="text-wrapper-4"
              type="number"
              step="1"
              placeholder="Informe o número de sessões"
              value={sessoes}
              onChange={(e) => setSessoes(parseInt(e.target.value))}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </div>
      </div>
      <FormFooter
        type={tempo && sessoes ? "active" : "inactive"}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default Tempo;
