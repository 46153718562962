import React from "react";
import "./FormFooter.css";

export const FormFooter = ({text, type, onClick }) => {
  return (
    <div className="form-footer">
        <div className="footer">
            <div className={`botao-${type}`} onClick={type === 'active' ? onClick : null}>
              <div className="text-wrapper-footer">{text ? text : 'Continuar'}</div>
              <img
                className="arrow-forward"
                alt="Arrow forward"
                src="/img/form/arrow-forward.png"
              />
            </div>
          </div>
    </div>
  );
};

export default FormFooter;
