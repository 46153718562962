import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ViewClient.css";
import { logout } from "../../api/api";
import SelectTattooArtist from "../components/SelectTattooArtist/SelectTattooArtist";
import Modal from "../components/Modal/Modal";

function ViewClient() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [showSelectArtist, setShowSelectArtist] = useState(false);

  const handleRequestBudgetClick = () => {
    setShowSelectArtist(true);
  };

  const handleCloseModal = () => {
    setShowSelectArtist(false);
  };

  return (
    <div className="view-client-container">
      <h1>Cliente</h1>
      <div className="view-client-profile">
        <div className="profile-picture">
          <h3>{user.name}</h3>
        </div>
        <Link to="/login" className="logout-button" onClick={logout}>
          Sair
        </Link>
      </div>
      <div className="view-client-budgets">
        <div className="view-client-budgets-open">
          <h3>Orçamentos abertos</h3>
        </div>
        <div className="view-client-budgets-answered">
          <h3>Orçamentos respondidos</h3>
        </div>
      </div>
      <div className="request-budget-button-container">
        <button className="request-budget-button" onClick={handleRequestBudgetClick}>
          Solicitar Orçamento
        </button>
      </div>
      <Modal show={showSelectArtist} onClose={handleCloseModal}>
        <SelectTattooArtist />
      </Modal>
    </div>
  );
}

export default ViewClient;