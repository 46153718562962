import React, { useState, useEffect } from 'react';

const Table = ({ locais, onSave }) => {
  const [localList, setLocalList] = useState([...locais]);

  // Atualiza o estado `localList` sempre que `locais` for alterado
  useEffect(() => {
    setLocalList([...locais]);
  }, [locais]);

  const handleChange = (index, key, value) => {
    const updatedLocais = [...localList];
    updatedLocais[index][key] = value;
    setLocalList(updatedLocais);
  };

  const handleAdd = () => {
    setLocalList([...localList, { id: 0, name: '', img: '' }]);
  };

  const handleRemove = (index) => {
    const updatedLocais = localList.filter((_, i) => i !== index);
    setLocalList(updatedLocais);
  };

  const handleSave = () => {
    onSave(localList);
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Imagem</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {localList.map((local, index) => (
            <tr key={local.id}>
              <td>
                <input
                  type="number"
                  value={local.id}
                  onChange={(e) => handleChange(index, 'id', parseFloat(e.target.value))}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={local.name}
                  onChange={(e) => handleChange(index, 'name', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={local.img}
                  onChange={(e) => handleChange(index, 'img', e.target.value)}
                />
              </td>
              <td>
                <button onClick={() => handleRemove(index)}>Remover</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleAdd}>Adicionar</button>
      <button onClick={handleSave}>Salvar</button>
    </div>
  );
};

export default Table;
