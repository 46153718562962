import React, { useState, useContext, useRef } from "react";
import "./Foto.css";
import { FormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";
import Modal from "./../../components/Modal/Modal";

import { useFormStateValidation } from "./FormStateValidation";

export const Foto = () => {
  useFormStateValidation();
  
  const navigate = useNavigate();
  const { formState, updateForm } = useContext(FormContext);
  const [foto, setFoto] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const fileInputRef = useRef(null);

  const handleDivClick = () => {
    fileInputRef.current.click(); // Dispara o clique no input de arquivo
  };

  const anexarFoto = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          let width = img.width;
          let height = img.height;
          const hdWidth = 1280;
          const hdHeight = 720;

          // Mantém a proporção da imagem
          if (width > height) {
            if (width > hdWidth) {
              height *= hdWidth / width;
              width = hdWidth;
            }
          } else {
            if (height > hdHeight) {
              width *= hdHeight / height;
              height = hdHeight;
            }
          }

          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          const resizedBase64 = canvas.toDataURL("image/jpeg");

          setFoto(resizedBase64);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  
  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalVisible(true);
  };

  const handleSubmit = () => {
    updateForm({
      ...formState,
      foto,
    });
    if(formState.tipo.id === 1){
      navigate("../cores");  
    }
    if(formState.tipo.id === 2){
      navigate("../detalhes");  
    }
    
  };
  return (
    <div>
      <FormHeader
        rightText={formState.tipo.id === 1 ? "passo 6/10" : "passo 2/3"}
        title={"Poderia me enviar uma foto do local que pretende tatuar?"}
      />
      <div className="body-foto">
        <p className="text-wrapper-2">
          Isso me ajuda a entender a complexidade do projeto
        </p>
        {foto && (
          <div className="frame-1">
            <div className="frame-2">
              <div className="image-container">
                <img
                  className="image-square"
                  src={foto}
                  alt={`Referência de Foto`}
                  onClick={() => openModal(foto)}
                />
                <div className="delete-icon" onClick={() => setFoto('')}>
                  <img src={"/img/form/remove.svg"} alt="Delete icon" />
                </div>
              </div>
            </div>
          </div>
        )}
        {!foto && (
          <div className="frame-3" onClick={handleDivClick}>
            <input
              type="file"
              accept="image/*"
              capture="environment"
              onChange={anexarFoto}
              style={{ display: "none" }} // Oculta o input
              ref={fileInputRef} // Adiciona a referência ao input
            />
            <img
              className="add-photo-alternate"
              alt="Add icon alternate"
              src="/img/form/foto/add-photo-alternate.png"
            />
            <p className="text-wrapper-3">Clique aqui para enviar a foto</p>
          </div>
        )}
      </div>
      <FormFooter onClick={handleSubmit} type={"active"} />
      {modalVisible && (
        <Modal show={modalVisible} onClose={() => setModalVisible(false)}>
          <img
            src={selectedImage}
            alt="Imagem em tamanho original"
            className="image-modal-referencia"
          />
        </Modal>
      )}
    </div>
  );
};

export default Foto;
