import React, { useContext, useState, useEffect } from "react";
import { RadioButton } from "../../components/RadioButton";
import "./Estilo.css";
import { FormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";

import { useFormStateValidation } from "./FormStateValidation";

export const Estilo = () => {
  useFormStateValidation();
  
  
  const { formState, updateForm } = useContext(FormContext);
  const estilos = formState?.artista?.estilos ? formState.artista.estilos : []
  const navigate = useNavigate();
  const [estiloSelecionado, setEstiloSelecionado] = useState(null);

  useEffect(() => {
    const fetchForm = () => {
      formState.estilo
        ? setEstiloSelecionado(formState.estilo)
        : setEstiloSelecionado(null);
    };
    fetchForm();
  }, [formState.estilo]);

  const handleEstiloClick = (estilo) => {
    setEstiloSelecionado(estilo._id === estiloSelecionado?._id ? null : estilo);
  };

  const handleSubmit = () => {
    const { img, ...rest } = estiloSelecionado;
    updateForm({
      ...formState,
      estilo: rest,
    });
    navigate("../referencia");
  };

  return (
    <div>
      <FormHeader
        rightText={"passo 8/10"}
        title={"Qual é o estilo da tatuagem?"}
      />
      <div className="body-estilo">
        <div className="frame-4">
          {estilos.map((estilo) => (
            <div
              className={`frame-5`}
              key={estilo._id}
              onClick={() => handleEstiloClick(estilo)}
            >
              <div
                className={`frame-6${
                  estiloSelecionado?._id === estilo._id
                    ? "-checked"
                    : ""
                }`}
                style={{ backgroundImage: `url(${estilo.img})` }}
              >
                <div className="frame-7">
                  <div className={`text-wrapper-2${
                            [].includes(estilo.name)
                              ? "-black"
                              : ""
                          }`}>{estilo.name}</div>
                  <RadioButton
                    className="radio-button-instance"
                    state="default"
                    type={
                      estiloSelecionado?._id === estilo._id
                        ? "checked"
                        : "unchecked"
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <FormFooter
        onClick={handleSubmit}
        type={estiloSelecionado ? "active" : "inactive"}
      />
    </div>
  );
};

export default Estilo;
