import React, { useContext, useState } from "react";
import "./Mensagem.css";
import { FormContext } from "./FormContext";
import { FormHeader } from "./FormHeader";
import { FormFooter } from "./FormFooter";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { updateOneForm } from "../../../api/api";

import { useFormStateValidation } from "./FormStateValidation";

export const Mensagem = () => {
  useFormStateValidation();
  const { formState, updateForm, resetForm } = useContext(FormContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [mensagem, setMensagem] = useState(user.text3 ? user.text3.replace(/{nomecliente}/g, formState.form?.name || '') : `Olá, ${formState.form?.name}!
  
${user.name} aqui. Finalizei o orçamento para sua tatuagem.
  
Confira o valor e os detalhes:
  
Valor total:
R$ ${parseFloat(formState.data?.valor).toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
  })}
  
Valor de sinal para agendamento:
R$ ${parseFloat(formState.data?.sinal).toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
  })}
  
Tempo e sessões:
${new Date(formState.data?.tempo).toLocaleTimeString("pt-BR", {
  hour: "2-digit",
  minute: "2-digit",
})} em ${formState.data?.sessoes} sessões

Obs: valor de sinal é abatido do valor total! 
  
Sugestão de agendamento:
${new Date(formState.data?.agendamento).toLocaleString("pt-BR", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
})}

Qualquer dúvida estou a disposição!`);

  const handleSubmit = async () => {
    updateForm({
      ...formState,
      data: {
        ...formState.data,
        mensagem,
      },
    });
    try {
      const data = await updateOneForm({
        _id: formState._id,
        data: { ...formState.data, mensagem, answered: true },
      });
      toast.success(data.msg);
      resetForm();
      const whatsappNumber = formState.form.phone;
      const whatsappMessage = encodeURIComponent(mensagem);
      const link = document.createElement("a");
      link.href = `whatsapp://send?phone=55${whatsappNumber}&text=${whatsappMessage}`;
      link.click();
      navigate("../final");
    } catch (error) {
      console.error(error);
      if (error.response.data.msg) {
        toast.error(error.response.data.msg);
      } else if (error.request) {
        toast.error("Erro ao fazer a solicitação. Por favor, tente novamente.");
      } else {
        toast.error("Erro desconhecido. Por favor, tente novamente.");
      }
    }
  };

  return (
    <div className="container-pai">
      <FormHeader
        rightText={"passo 5/5"}
        midText={"Enviar Orçamento"}
        title={`Revise a mensagem que será enviada ao cliente`}
      />
      <div className="body-mensagem">
        <div className="ol-theresa-webb-nome-wrapper">
          <textarea
            className="ol-theresa-webb-nome"
            value={mensagem}
            onChange={(e) => setMensagem(e.target.value)}
          />
        </div>
      </div>
      <FormFooter type={"active"} onClick={handleSubmit} />
    </div>
  );
};

export default Mensagem;
