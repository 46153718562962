import React, { useContext, useState, useEffect } from "react";
import { RadioButton } from "../../components/RadioButton";
import "./Cobertura.css";
import { FormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";

// Tela reaproveitada de cores, mas eh pra tipo (nova ou cobertura)

import { useFormStateValidation } from "./FormStateValidation";

export const Cobertura = () => {
  useFormStateValidation();
  
  const cores = [
    {
      id: 1,
      name: "Tatuagem nova",
    },
    {
      id: 2,
      name: "Cobertura ou reforma",
    }
  ];

  const { formState, updateForm } = useContext(FormContext);
  const navigate = useNavigate();
  const [corSelecionada, setCorSelecionada] = useState(null);

  useEffect(() => {
    const fetchForm = () => {
      formState.cor
        ? setCorSelecionada(formState.cor)
        : setCorSelecionada(null);
    };
    fetchForm();
  }, [formState.cor]);

  const handleCorClick = (cor) => {
    setCorSelecionada(cor.id === corSelecionada?.id ? null : cor);
  };

  const handleSubmit = () => {
    updateForm({
      ...formState,
      tipo: corSelecionada,
    });
    if(corSelecionada.id === 1){
      navigate("../regiao");
    }
    if(corSelecionada.id === 2){
      navigate("../foto");
    }
    
  };

  return (
    <div>
      <FormHeader
        rightText={""}
        title={"Você está buscando fazer uma tatuagem nova ou reformar/cobrir uma antiga?"}
      />

      <div className="body-cores">
        <div className="frame-3">
          {cores.map((cor) => (
            <div
              className={`frame-4 ${
                corSelecionada && corSelecionada.id === cor.id && "selected"
              }`}
              key={cor.id}
              onClick={() => handleCorClick(cor)}
            >
              <div className={`frame-5`}>
                <RadioButton
                  className="radio-button-instance"
                  state="default"
                  type={corSelecionada?.id === cor.id ? "checked" : "unchecked"}
                />
                <div className="frame-6">
                  <div className="frame-7">
                    <div className="text-wrapper-2">{cor.name}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <FormFooter
        onClick={handleSubmit}
        type={corSelecionada ? "active" : "inactive"}
      />
    </div>
  );
};

export default Cobertura;
