import React from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';
import { logout } from '../../../api/api'

function Sidebar() {
  return (
    <div className="sidebar">
      <h2>Painel Admin</h2>
      <ul>
        <li>
          <NavLink to="/" activeclassname="active">Home</NavLink>
        </li>
        <li>
          <NavLink to="locais" activeclassname="active">Locais</NavLink>
        </li>
        <li>
          <NavLink to="estilos" activeclassname="active">Estilos</NavLink>
        </li>
        <li>
          <NavLink to="usuarios" activeclassname="active">Usuários</NavLink>
        </li>

        <li>
          <NavLink to="whatsapp" activeclassname="active">Whatsapp</NavLink>
        </li>
      </ul>
      <button className="logout-button" onClick={logout}>Sair</button>
    </div>
  );
}

export default Sidebar;
