import React, { useContext, useState, useEffect } from "react";
import "./Local.css";
import { FormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";

import Check from "../../SVG/Check";

import { useFormStateValidation } from "./FormStateValidation";
import { getLocais } from "../../../api/api";

export const Local = () => {
  useFormStateValidation();

  const [locais, setLocais] = useState([]);

  const { formState, updateForm } = useContext(FormContext);
  const navigate = useNavigate();
  const [localSelecionado, setLocalSelecionado] = useState(null);

  useEffect(() => {
    const fetchForm = () => {
      formState.local
        ? setLocalSelecionado(formState.local)
        : setLocalSelecionado(null);
    };
    fetchForm();
  }, [formState.local]);

  useEffect(() => {
    const fetchLocais = async () => {
      const locais = await getLocais();
      const newLocais = locais.filter(
        (local) => local.regiaoid === formState.regiao?._id
      );
      if (newLocais.length !== 0) {
        setLocais(newLocais[0].locais);
      }
    };
    fetchLocais();
  }, [formState.regiao]);

  const handleLocalClick = (local) => {
    setLocalSelecionado(local.id === localSelecionado?.id ? null : local);
  };

  const handleSubmit = () => {
    updateForm({
      ...formState,
      local: localSelecionado,
    });
    if(localSelecionado.id === 1){
      navigate("../foto");
    }else{
      navigate("../tamanho");
    }
    
  };

  return (
    <div>
      <FormHeader
        rightText={"passo 4/10"}
        title={"É em um local específico ou área total?"}
      />

      <div className="body-local">
        <div className="frame-4">
          {locais.map((local) => (
            <div
              className={`frame-5 ${
                !localSelecionado
                  ? ""
                  : localSelecionado && localSelecionado.id === local.id
                  ? "selected"
                  : "not-selected"
              }`}
              key={local.id}
              onClick={() => handleLocalClick(local)}
            >
              <img className="img" alt="Frame" src={local.img} />
              <div className="text-wrapper-2">{local.name}</div>
              {localSelecionado && localSelecionado.id === local.id && (
                <Check className="check-icon2" />
              )}
            </div>
          ))}
        </div>
        <div className="checkbox">
          <div
            className="check-box"
            onClick={() =>
              handleLocalClick({ id: 0, name: "Ainda não decidi", img: null })
            }
          >
            {localSelecionado && localSelecionado.id === 0 && (
              <Check className="check-icon" />
            )}
          </div>
          <div className="check-box-label">Ainda não decidi</div>
        </div>
      </div>

      <FormFooter
        type={localSelecionado ? "active" : "inactive"}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default Local;
