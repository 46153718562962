import React, { useState, useRef, useEffect } from "react";

const Teste = () => {
  const [foto, setFoto] = useState(null);
  const [imagemEditada, setImagemEditada] = useState(null);
  const fileInputRef = useRef(null);
  const canvasRef = useRef(null);
  const [isPainting, setIsPainting] = useState(false);
  const [points, setPoints] = useState([]);

  useEffect(() => {
    if (foto) {
      drawImageOnCanvas(foto);
    }
    // eslint-disable-next-line
  }, [foto]);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const anexarFoto = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          setFoto(img);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const drawImageOnCanvas = (image) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    // Redesenhar a edição
    if (imagemEditada) {
      const editedImg = new Image();
      editedImg.onload = () => {
        ctx.drawImage(editedImg, 0, 0, canvas.width, canvas.height);
      };
      editedImg.src = imagemEditada;
    }
  };

  const startPainting = (event) => {
    event.preventDefault(); // Impede a rolagem da página

    setIsPainting(true);

    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.touches[0].clientX - rect.left;
    const y = event.touches[0].clientY - rect.top;

    setPoints([...points, { x, y }]);
  };

  const stopPainting = () => {
    setIsPainting(false);

    if (points.length > 2) {
      // Desenhar a área preenchida se a linha se fechar
      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");
      ctx.globalAlpha = 0.6; // Opacidade de 60%
      ctx.fillStyle = "blue";
      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);

      for (let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i].x, points[i].y);
      }

      ctx.closePath();
      ctx.fill();

      ctx.globalAlpha = 1; // Restaurar a opacidade padrão
    }

    setPoints([]);
  };

  const paint = (event) => {
    if (!isPainting) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    ctx.strokeStyle = "rgba(0, 0, 255, 0.6)"; // Azul com 60% de opacidade
    ctx.lineWidth = 5;
    ctx.lineCap = "round";

    const rect = canvas.getBoundingClientRect();
    const x = event.touches[0].clientX - rect.left;
    const y = event.touches[0].clientY - rect.top;

    ctx.lineTo(x, y);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(x, y);

    // Atualizar os pontos para fechar a área se necessário
    setPoints([...points, { x, y }]);
  };

  const handleCanvasClick = (event) => {
    event.preventDefault();
    startPainting(event);
  };

  const handleMouseMove = (event) => {
    event.preventDefault();
    paint(event);
  };

  const handleTouchStart = (event) => {
    event.preventDefault();
    startPainting(event);
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
    paint(event);
  };

  const handleTouchEnd = () => {
    stopPainting();
  };

  const handleSave = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const editedImage = canvas.toDataURL("image/jpeg");
    setImagemEditada(editedImage);
    console.log("Imagem editada salva:", editedImage);
  };

  return (
    <div>
      <style>
        {`.image-container { touch-action: none; }`} {/* Impede que a tela seja arrastada ao desenhar */}
      </style>
      <div className="body-foto">
        {foto && (
          <div className="frame-1">
            <div className="frame-2">
              <div className="image-container">
                <canvas
                  ref={canvasRef}
                  onMouseDown={handleCanvasClick}
                  onMouseUp={stopPainting}
                  onMouseMove={handleMouseMove}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                  width={400} // Defina as dimensões do canvas
                  height={400}
                  style={{ cursor: "crosshair", border: "1px solid black" }}
                />
              </div>
            </div>
          </div>
        )}
        {!foto && (
          <div className="frame-3" onClick={handleDivClick}>
            <input
              type="file"
              accept="image/*"
              capture="environment"
              onChange={anexarFoto}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <img
              className="add-photo-alternate"
              src="/img/form/foto/add-photo-alternate.png"
              alt="Ícone de adicionar foto"
            />
            <p className="text-wrapper-3">Clique aqui para enviar a foto</p>
          </div>
        )}
      </div>

      <button onClick={handleSave}>Salvar Imagem Editada</button>

      <div>
        {imagemEditada && (
          <img
            src={imagemEditada}
            alt="Imagem editada"
            style={{ maxWidth: "100%", marginTop: "20px" }}
          />
        )}
      </div>
    </div>
  );
};

export default Teste;
