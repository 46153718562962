import React, { useContext, useState, useEffect } from "react";
import "./Regiao.css";
import { FormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";
import FormHeader from "./FormHeader";
import FormFooter from "./FormFooter";

import Check from "../../SVG/Check";

import { useFormStateValidation } from "./FormStateValidation";

import { getLocais } from "../../../api/api";

export const Regiao = () => {
  useFormStateValidation();

  const navigate = useNavigate();
  const { formState, updateForm } = useContext(FormContext);
  const regioes = formState?.artista?.regioes ? formState.artista.regioes : []
  const [regiaoSelecionada, setRegiaoSelecionada] = useState(null);

  useEffect(() => {
    const fetchForm = () => {
      formState.regiao
        ? setRegiaoSelecionada(formState.regiao)
        : setRegiaoSelecionada(null);
    };
    fetchForm();
  }, [formState.regiao]);

  const handleRegiaoClick = (regiao) => {
    setRegiaoSelecionada(regiao._id === regiaoSelecionada?._id ? null : regiao);
  };

  const handleSubmit = async () => {
    updateForm({
      ...formState,
      regiao: regiaoSelecionada,
      local: null,
    });
    const locais = await getLocais();
    const newLocais = locais.filter(
      (local) => local.regiaoid === regiaoSelecionada?._id
    );
    if (newLocais[0]?.locais.length !== 0) {
      navigate("../local");
      return;
    }
    navigate("../tamanho");
  };

  return (
    <div>
      <FormHeader
        rightText={"passo 3/10"}
        title={"Me diga a região do corpo que você quer tatuar"}
      />

      <div className="body-regiao">
        <div className="frame-4">
          {regioes.map((regiao) => (
            <div className={`frame-5`} key={regiao._id}>
              <img
                className={`frame-6 ${
                  !regiaoSelecionada
                    ? ""
                    : regiaoSelecionada && regiaoSelecionada._id === regiao._id
                    ? "selected"
                    : "not-selected"
                }`}
                alt="Frame"
                src={regiao.img}
                onClick={() => handleRegiaoClick(regiao)}
              />
              <div className="text-wrapper-2">{regiao.name}</div>
              {regiaoSelecionada && regiaoSelecionada._id === regiao._id && (
                <Check className="check-icon2" />
              )}
            </div>
          ))}
        </div>
        <div className="checkbox">
          <div
            className="check-box"
            onClick={() =>
              handleRegiaoClick({ _id: 0, name: "Ainda não decidi", img: null })
            }
          >
            {regiaoSelecionada && regiaoSelecionada._id === 0 && (
              <Check className="check-icon" />
            )}
          </div>
          <div className="check-box-label">Ainda não decidi</div>
        </div>
      </div>

      <FormFooter
        type={regiaoSelecionada ? "active" : "inactive"}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default Regiao;
