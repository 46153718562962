import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import PrivateRoute from "../pages/PrivateRoute";
import ViewArtist from "../pages/ViewArtist/ViewArtist";
import ViewClient from "../pages/ViewClient/ViewClient";
import Unauthorized from "../pages/Unauthorized";
import Form from "../pages/ViewClient/Form/Form";
import { FormProvider } from "../pages/ViewClient/Form/FormContext";
import Contact from "../pages/ViewClient/Form/Contact";
import Cobertura from "../pages/ViewClient/Form/Cobertura"
import Regiao from "../pages/ViewClient/Form/Regiao";
import Local from "../pages/ViewClient/Form/Local";
import Tamanho from "../pages/ViewClient/Form/Tamanho";
import Cores from "../pages/ViewClient/Form/Cores";
import Estilo from "../pages/ViewClient/Form/Estilo";
import Referencia from "../pages/ViewClient/Form/Referencia";
import Foto from "../pages/ViewClient/Form/Foto";
import Detalhes from "../pages/ViewClient/Form/Detalhes";
import Final from "../pages/ViewClient/Form/Final";

// Teste
import Teste from "../pages/Teste/Teste"

// Artista
import { FormProviderArtist } from "../pages/ViewArtist/Form/FormContext";
import FormArtist from "../pages/ViewArtist/Form/Form";
import Valor from "../pages/ViewArtist/Form/Valor";
import Sinal from "../pages/ViewArtist/Form/Sinal";
import Tempo from "../pages/ViewArtist/Form/Tempo";
import Agendamento from "../pages/ViewArtist/Form/Agendamento";
import Mensagem from "../pages/ViewArtist/Form/Mensagem";
import FinalArtist from "../pages/ViewArtist/Form/Final";


// Admex
import Sidebar from "../pages/Admex/Sidebar/Sidebar";
import Home from "../pages/Admex/Home";
import Locais from "../pages/Admex/Locais"
import Estilos from "../pages/Admex/Estilos";
import Usuarios from "../pages/Admex/Usuarios";
import Whatsapp from "../pages/Admex/Whatsapp";

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        path="/artist/*"
        element={
          <FormProviderArtist>
            <PrivateRoute allowedRoles={["artist"]}>
              <Routes>
                <Route path="/" element={<ViewArtist />} />
                <Route
                  path="form/*"
                  element={
                    <Routes>
                      <Route path="/" element={<FormArtist />} />
                      <Route path="valor" element={<Valor />} />
                      <Route path="sinal" element={<Sinal />} />
                      <Route path="tempo" element={<Tempo />} />
                      <Route path="agendamento" element={<Agendamento />} />
                      <Route path="mensagem" element={<Mensagem />} />
                      <Route path="final" element={<FinalArtist />} />
                    </Routes>
                  }
                />
              </Routes>
            </PrivateRoute>
          </FormProviderArtist>
        }
      />

      <Route
        path="/client/*"
        element={
          <PrivateRoute allowedRoles={["client"]}>
            <Routes>
              <Route path="/" element={<ViewClient />} />
              <Route
                path="form/*"
                element={
                  <FormProvider>
                    <Routes>
                      <Route path="/" element={<Form />} />
                      <Route path="contact" element={<Contact />} />
                      <Route path="cobertura" element={<Cobertura />} />
                      <Route path="regiao" element={<Regiao />} />
                      <Route path="local" element={<Local />} />
                      <Route path="tamanho" element={<Tamanho />} />
                      <Route path="cores" element={<Cores />} />
                      <Route path="estilo" element={<Estilo />} />
                      <Route path="referencia" element={<Referencia />} />
                      <Route path="foto" element={<Foto />} />
                      <Route path="detalhes" element={<Detalhes />} />
                      <Route path="final" element={<Final />} />
                    </Routes>
                  </FormProvider>
                }
              />
            </Routes>
          </PrivateRoute>
        }
      />

      <Route
        path="/:artistAlias/*"
        element={
          <FormProvider>
            <Routes>
              <Route path="/" element={<Form />} />
              <Route path="contact" element={<Contact />} />
              <Route path="cobertura" element={<Cobertura />} />
              <Route path="regiao" element={<Regiao />} />
              <Route path="local" element={<Local />} />
              <Route path="tamanho" element={<Tamanho />} />
              <Route path="cores" element={<Cores />} />
              <Route path="estilo" element={<Estilo />} />
              <Route path="referencia" element={<Referencia />} />
              <Route path="foto" element={<Foto />} />
              <Route path="detalhes" element={<Detalhes />} />
              <Route path="final" element={<Final />} />
            </Routes>
          </FormProvider>
        }
      />

      <Route
        path="/admex/*"
        element={
          <PrivateRoute allowedRoles={["admex"]}>
            <Sidebar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="locais" element={<Locais />} />
              <Route path="estilos" element={<Estilos />} />
              <Route path="usuarios" element={<Usuarios />} />
              <Route path="whatsapp" element={<Whatsapp />} />
            </Routes>
          </PrivateRoute>
        }
      />

      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Login />} />
      <Route path="/teste" element={<Teste />} />
    </Routes>
  );
}
