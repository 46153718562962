import React from 'react';

const DropdownList = ({ regiaoData, onSelect }) => {
  const handleChange = (e) => {
    const selectedId = e.target.value;
    const selectedRegiao = regiaoData.find(regiao => regiao._id === selectedId);
    onSelect(selectedRegiao);
  };

  return (
    <select onChange={handleChange} defaultValue="">
      <option value="" disabled>Selecione uma região</option>
      {regiaoData.map(regiao => (
        <option key={regiao._id} value={regiao._id}>
          {regiao.regiaoname}
        </option>
      ))}
    </select>
  );
};

export default DropdownList;
