import React from 'react';
import MaskedInput from 'react-text-mask';

const PhoneMasked = ({ value, onChange, className, placeholder, name }) => {
  const digitCount = (value.match(/\d/g) || []).length;

  const mask = digitCount > 10
    ? ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    : ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d?/];

  const handleChange = (e) => {
    const unformattedValue = e.target.value.replace(/\D/g, '');
    onChange(unformattedValue);
  };

  return (
    <MaskedInput
      mask={mask}
      className={className}
      placeholder={placeholder}
      guide={true} // Allow the user to delete beyond the "-"
      value={value}
      onChange={handleChange}
      name={name}
    />
  );
};

export default PhoneMasked;