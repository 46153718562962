import React, { useContext, useEffect } from "react";
import "./Final.css";
import { useNavigate, useParams } from "react-router-dom";
import { FormContext } from "./FormContext";


export const Final = () => {

  const navigate = useNavigate();
  const { artistAlias } = useParams();
  const { formState, resetForm } = useContext(FormContext);
  const mensagemPadrao = `Prontinho! Recebi as suas informações.

Agora vou analisar o seu projeto e em até 5 dias entro em contato com você.

Até logo :)`

  const goBack = () => {
    resetForm();
    navigate(`/${artistAlias}`);
  };

  useEffect(() => {
    if(!formState?.artista){
      navigate(`/${artistAlias}`);
    }
// eslint-disable-next-line
  },[])
  
  return (
    <div className="final">
      <div className="container">
        <>
          <img
            className="rectangle"
            alt="Rectangle"
            src={formState?.artista?.fotocapa}
          />
          <div className="gradient-overlay"></div>
        </>
        <div className="frame">
          <img
            className="ellipse"
            alt="Ellipse"
            src={formState?.artista?.fotoperfil}
          />
        </div>
        <div className="informa-es-recebidas-wrapper">
          <p className="informa-es-recebidas">
            {formState?.artista?.text2 ? formState.artista.text2 : mensagemPadrao}
          </p>
        </div>
      </div>

      <div className="frame-wrapper2">
        <div className="div-wrapper" onClick={goBack}>
          <div className="text-wrapper">Fazer outro orçamento</div>
        </div>
      </div>
    </div>
  );
};

export default Final;
