import React, { useContext, useState, useEffect } from "react";
import "./Sinal.css";
import { FormContext } from "./FormContext";
import { FormHeader } from "./FormHeader";
import { FormFooter } from "./FormFooter";
import { useNavigate } from "react-router-dom";

import formatCurrency from "../../../funcs/formatCurrency";

import Check from "../../SVG/Check";

import { useFormStateValidation } from "./FormStateValidation";

export const Sinal = () => {
  useFormStateValidation();

  const { formState, updateForm } = useContext(FormContext);
  const navigate = useNavigate();
  const [sinal, setSinal] = useState(null);

  useEffect(() => {
    const fetchForm = () => {
      if (formState.data?.sinal !== undefined && formState.data?.sinal !== null) {
        setSinal(formState.data.sinal * 100);
    } else {
        setSinal(null);
    }
    };
    fetchForm();
  }, [formState.data?.sinal]);

  const handleInputChange = (event) => {
    const input = event.target;
    const inputValue = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
    const numericValue = parseInt(inputValue, 10);

    if (!isNaN(numericValue)) {
      setSinal(numericValue);
      setTimeout(() => {
        // Define a posição do cursor para o final do valor atualizado
        input.selectionStart = input.selectionEnd = input.value.length;
      }, 0);
    }
  };

  const handleSubmit = () => {
    const valorDecimal = sinal / 100;
    updateForm({
      ...formState,
      data: {
        ...formState.data,
        sinal: valorDecimal
      } 
      
    });
    navigate("../tempo");
  };

  return (
    <div>
      <FormHeader
        rightText={"passo 2/5"}
        midText={"Enviar Orçamento"}
        title={`Qual o valor do sinal?`}
      />
      <div className="body-sinal">
        <div className="frame-3">
          <div className="text-wrapper-2">R$</div>
          <input
            type="text"
            inputMode="numeric"
            className="text-wrapper-3"
            value={formatCurrency(sinal ? sinal : 0)}
            onChange={handleInputChange}
          />
        </div>
        <div className="checkbox">
          <div
            className="check-box"
            onClick={() =>
                setSinal(sinal === 0 ? null : 0)
            }
          >
            {sinal === 0 && (
              <Check className="check-icon" />
            )}
          </div>
          <div className="check-box-label">Não haverá valor de sinal</div>
        </div>
      </div>
      <FormFooter type={sinal || sinal === 0 ? "active" : "inactive"} onClick={handleSubmit} />
    </div>
  );
};

export default Sinal;
