import React from 'react';

function Estilos() {
  return (
    <div className="content">
      <h1>Estilos</h1>
    </div>
  );
}

export default Estilos;
