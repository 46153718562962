import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormContext } from './FormContext';

export const useFormStateValidation = () => {
  const { formState } = useContext(FormContext);
  const navigate = useNavigate();
  const { artistAlias } = useParams();

  useEffect(() => {
    // Verifica se o objeto formState está vazio
    if (!formState.artistid) {
      navigate(`/${artistAlias}`); // Altere para a rota correta se necessário
      return
    }
    
  }, [formState, navigate, artistAlias]);
};