// Slide.js
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import "./Slide.css";

export const Slide = ({ className, tamanhos, onChange }) => {
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState(0);
  const slideRef = useRef(null);

  useEffect(() => {
    if (slideRef.current) {
      const stepWidth = slideRef.current.clientWidth / (tamanhos.length - 1);
      const initialPosition = tamanhos.findIndex(t => t === tamanhos[0]) * stepWidth;
      setPosition(initialPosition); // Define a posição inicial com base no tamanho inicial
      onChange(tamanhos[0]); // Define o tamanho inicial
    }
  }, [tamanhos, onChange]);

  const startDragging = (clientX) => {
    const rect = slideRef.current.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const clampedPosition = Math.min(Math.max(offsetX, 0), rect.width);
    setPosition(clampedPosition);
    setDragging(true);
    document.body.classList.add('no-scroll'); // Bloqueia o scroll na página
  };

  const stopDragging = () => {
    setDragging(false);
    document.body.classList.remove('no-scroll'); // Libera o scroll na página
    const stepWidth = slideRef.current.clientWidth / (tamanhos.length - 1);
    const stepIndex = Math.round(position / stepWidth);
    setPosition(stepIndex * stepWidth);
    onChange(tamanhos[stepIndex]);
  };

  const drag = (clientX) => {
    if (!dragging) return;

    const rect = slideRef.current.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const clampedPosition = Math.min(Math.max(offsetX, 0), rect.width);
    setPosition(clampedPosition);

    const stepWidth = slideRef.current.clientWidth / (tamanhos.length - 1);
    const stepIndex = Math.round(clampedPosition / stepWidth);
    onChange(tamanhos[stepIndex]);
  };

  const handleMouseDown = (e) => {
    startDragging(e.clientX);
  };

  const handleMouseMove = (e) => {
    e.preventDefault(); // Previne o comportamento padrão do navegador
    drag(e.clientX);
  };

  const handleMouseUp = () => {
    stopDragging();
  };

  const handleTouchStart = (e) => {
    e.preventDefault(); // Previne o comportamento padrão do navegador
    startDragging(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    e.preventDefault(); // Previne o comportamento padrão do navegador
    drag(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    stopDragging();
  };

  const currentIndex = Math.round(position / (slideRef.current?.clientWidth / (tamanhos.length - 1)));

  return (
    <div
      className={`handle-tamanho ${className}`}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      ref={slideRef}
    >
      <div className="rectangle-tamanho" />
      <div
        className="fill-tamanho"
        style={{ width: `${position + 5}px` }} // Ajusta o preenchimento com base na posição da elipse
      />
      <div
        className="ellipse-tamanho"
        style={{ left: `${position}px` }}
      />
      <div
        className="frame-tamanho"
        style={{ left: `${position}px`, transform: 'translateX(-50%)' }}
      >
        <div className="element-wrapper-tamanho">
          <div className="element-tamanho">{tamanhos[currentIndex]?.name || "Micro"}</div>
        </div>
      </div>
    </div>
  );
};

Slide.propTypes = {
  className: PropTypes.string,
  tamanhos: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Slide;
