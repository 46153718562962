import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SelectTattooArtist.css";
import { getArtists } from "../../../api/api";
import { toast } from "react-toastify";

function SelectTattooArtist() {
  const [selectedArtist, setSelectedArtist] = useState(null);
  const navigate = useNavigate();
  const [artists, setArtists] = useState([]);

useEffect(() => {
  const fetchArtists = async () => {
    try {
      const data = await getArtists();
      setArtists(data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        // O pedido foi feito e o servidor respondeu com um status fora do intervalo de 2xx
        toast.error(error.response.data.msg);
      } else if (error.request) {
        // O pedido foi feito, mas nenhuma resposta foi recebida
        toast.error(
          "Erro ao fazer a solicitação. Por favor, tente novamente."
        );
      } else {
        // Algo aconteceu na configuração do pedido que disparou um erro
        toast.error("Erro desconhecido. Por favor, tente novamente.");
      }
    }
  };
  fetchArtists();
}, []);



  const handleArtistClick = (artist) => {
    setSelectedArtist(artist);
  };

  const handleNextClick = () => {
    if (selectedArtist) {
      navigate(`/client/form?arid=${selectedArtist._id}`);
    }
  };

  return (
    <div className="select-artist-container">
      <input type="text" placeholder="Buscar tatuador" />
      <div className="artist-list">
        {artists.map((artist) => (
          <div
            key={artist._id}
            className={`artist-icon ${
              selectedArtist && selectedArtist._id === artist._id
                ? "selected"
                : ""
            }`}
            onClick={() => handleArtistClick(artist)}
          >
            <p className="artist-icon-text">{artist.name}</p>
          </div>
        ))}
      </div>
      <button onClick={handleNextClick}>Avançar para o formulário</button>
    </div>
  );
}

export default SelectTattooArtist;
