import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, allowedRoles }) => {
  const isAuth = !!localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user ? user.role : null;

  if (!isAuth || !allowedRoles.includes(userRole)) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default PrivateRoute;