// FormContext.js
import React, { createContext, useState } from 'react';

export const FormContext = createContext();

export const FormProviderArtist = ({ children }) => {
  const [formState, setFormState] = useState({});

  const updateForm = (newState) => {
    setFormState(prevState => ({ ...prevState, ...newState }));
  };

  const resetForm = () => {
    setFormState({}); // ou qualquer que seja o estado inicial do seu formulário
  };

  return (
    <FormContext.Provider value={{ formState, updateForm, resetForm }}>
      {children}
    </FormContext.Provider>
  );
};